<template>
  <main class="page">
    <div class="page__grid">
      <h1 class="page__header">Start composing your unique legacy!</h1>

      <p class="page__description">
        There is nothing as amazing as creating or being part of something
        greater than yourself. Here you can quickly and conveniently compose a
        powerful heartfelt letter to your future generations. The letter will
        provide advice, lessons, and blessings to generations both current and
        yet unborn.
      </p>

      <div class="step">
        <p class="step__text step__text-lg">It’s simple and easy</p>
        <p class="step__text">First let’s get you set up</p>
        <div class="step__input">
          <div class="field-row">
            <div class="field">
              <div class="field__wrap">
                <input
                  class="field__input"
                  type="text"
                  placeholder="Name"
                  autocomplete="false"
                  v-model="account.name"
                  required
                />
              </div>
            </div>
            <div class="field">
              <div class="field__wrap">
                <input
                  class="field__input"
                  type="email"
                  placeholder="Email"
                  autocomplete="false"
                  v-model="account.email"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <button
            @click="routeToNextPage"
            class="button button--primary"
            :disabled="!validateInput"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/home.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'

export default {
  name: 'Index',
  data() {
    return {
      account: {
        name: '',
        email: '',
      },
    }
  },
  computed: {
    validateInput() {
      return !(
        this.account.name === '' ||
        this.account.email === '' ||
        (this.account.email &&
          !this.account.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ))
      )
    },
  },
  created() {
    storage.clearLocalStorage()
    storage.setUpLocalStorage()
    // let storedData = storage.getDataFromLocalStorage('account')
    // if (storedData) {
    //   this.account = storedData
    // }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('account', this.account)
      this.$router.push({ name: 'DocumentCaption' })
    },
  },
}
</script>

